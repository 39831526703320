/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//代理相关
const agent = {
    name: 'agent',
    apis: {
        /**
         * 获取所有代理的list
         * @param params
         * @returns {*}
         */
        agentList(params) {
            return httpFetch('post', '/api/agent/agentList', params);
        },
        /**
         * 代理详情
         */
        agentShow(params) {
            return httpFetch('post', `/api/agent/agentShow`,params);
        },
        /**
         * 代理发布
         * @param params
         * @returns {*}
         */
        agentPost(params) {
            return httpFetch('post', '/api/agent/agentCreate', params);
        },
        /**
         * 用户券领取
         * @param params
         * @returns {*}
         */
		majorAgentMoneyGet(params) {
            return httpFetch('post', '/api/agent/majorAgentMoneyGet', params);
        },
    }
};
export default agent;
